<template>
  <Popover class="relative">
    <div class="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
      <div class="flex justify-start lg:w-0 lg:flex-1">
        <a href="#">
          <span class="sr-only">TDN</span>
          <img class="h-16 w-auto sm:h-24" src="/logos/tdn-logo-dark.svg" alt="The Developers network" />
        </a>
      </div>
    </div>
  </Popover>
</template>
<script setup>
</script>