<template>
  <div id="app" class="flex flex-col h-screen justify-between bg-gray-900">
    <Logo></Logo>
    <main>
        <RouterView></RouterView>
    </main>
    <Footer />
  </div>
</template>

<script>
import RouterView from 'vue-router'
import Footer from "@/layout/Footer";
import Logo from '@/components/Logo.vue'

export default {
  name: 'app',
  components: { Logo, RouterView, Footer},
}
</script>

