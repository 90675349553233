<template>
    <div class="bg-gray-900">
        <HomepageHeader/>
        <div>
            <div class="max-w-7xl mx-auto pb-12 px-4 sm:pb-20 lg:px-8">
                <div class="text-center">
                    <p class="mt-1 text-4xl font-black text-gray-100 sm:text-5xl tracking-wide lg:text-6xl">The
                        Developers Network</p>
                    <p class="max-w-xl mt-5 mx-auto text-xl animate-text bg-gradient-to-r from-purple-500 via-tdn-blue to-zinc-100 bg-clip-text text-transparent">Focus on your strength, not your weaknesses.</p>
                </div>
            </div>
            <div class="max-w-5xl mx-auto bg-zinc-100  lg:shadow-tdn-blue p-8 lg:rounded-lg lg:animate-shadow lg:shadow-xl">
                <div class="px-4 py-5 sm:p-6">
                    <p class="text-lg leading-8">
                        Welkom bij The Developers Network (TDN)<br />Een community van developers die maandelijks bij elkaar
                        komen om kennis te delen, elkaar te helpen en samen te werken aan interessante projecten.<br/>
                        Elke eerste dinsdag van de maand ontmoeten we elkaar en geeft één van ons een presentatie.<br/>
                        Bijvoorbeeld iemand over een eigen compiler die hij aan het bouwen is, over een nieuwe
                        programmeertaal, of iemand die een gepersonaliseerd kinderboek heeft gegenereerd met AI.<br/>
                        Daarnaast houden we hackatons, waarbij we een binnen een dag een werkende applicatie proberen
                        te bouwen.
                    </p>
                </div>
            </div>
            <div class="max-w-5xl mx-auto my-24">
                <div class="text-center">
                    <h2 class="text-2xl font-bold text-center mb-8 text-white mx-2">Gemaakt door TDN leden</h2>
                    <ul role="list" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-4 lg:mx-auto">
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://www.huidigeenergieprijs.nl" target="_blank" class="block">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">HuidigeEnergieprijs.nl</h3>
                                    <p class="text-gray-600">Bekijk de huidige energieprijs.<br/>Deze site is gemaakt op
                                        een TDN hackathon.</p>
                                </div>
                            </a>
                        </li>
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://www.httpapi.io" target="_blank" class="block">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">httpapi.io</h3>
                                    <p class="text-gray-600">Vraag netwerkgegevens op via een http api</p>
                                </div>
                            </a>
                        </li>
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://www.kaniknuwassen.nl" target="_blank" class="block">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">kaniknuwassen.nl</h3>
                                    <p class="text-gray-600">Bekijk of op dit moment de energieprijs gunstig is om te wassen</p>
                                </div>
                            </a>
                        </li>
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://watisdefoutmelding.nl" target="_blank" class="block">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">WatIsDeFoutmelding.nl</h3>
                                    <p class="text-gray-600">Een hulpmiddel om een probleem uit te leggen aan een
                                        developer</p>
                                </div>
                            </a>
                        </li>
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://www.lamagaudie.com/nl" target="_blank" class="block">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">www.LaMagaudie.com</h3>
                                    <p class="text-gray-600">De website voor caming La Magaudie</p>
                                </div>
                            </a>
                        </li>
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://dnsdiff.io" target="_blank" class="block">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">DnsDiff.io</h3>
                                    <p class="text-gray-600">Een tool om DNS wijzigings&shy;verzoeken aan anderen door
                                        te geven en te monitoren</p>
                                </div>
                            </a>
                        </li>
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://norobo.org" target="_blank" class="block">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">norobo.org</h3>
                                    <p class="text-gray-600">
                                        Een site met tips voor developers om goede foutmeldingen te schrijven
                                    </p>
                                </div>
                            </a>
                        </li>
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://www.crocgui.app" target="_blank" class="block">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">CrocGui.app</h3>
                                    <p class="text-gray-600">Een app om makkelijk bestanden naar elkaar te versturen met
                                        behulp van <a href="https://github.com/schollz/croc" target="_blank">croc</a>
                                    </p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="max-w-5xl mx-auto my-24">
                <div class="text-center">
                    <h2 class="text-2xl font-bold text-center mb-8 text-white">Sites van TDN leden</h2>
                    <ul role="list" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mx-4 lg:mx-auto">
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://devwerk.nl" target="_blank">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">DevWerk.nl</h3>
                                    <p class="text-gray-600">Het bedrijf van Boyd, Maarten en Remco</p>
                                </div>
                            </a>
                        </li>
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://maartenv.dev" target="_blank">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">maartenv.dev</h3>
                                    <p class="text-gray-600">De persoonlijke site van Maarten</p>
                                </div>
                            </a>
                        </li>
                        <li class="rounded-lg bg-zinc-100 shadow-lg animate-shadow shadow-tdn-blue overflow-hidden hover:bg-white hover:underline focus:underline transition-colors duration-200 ease-in-out w-full max-w-lg mx-auto">
                            <a href="https://www.iamboyd.nl" target="_blank">
                                <div class="p-6">
                                    <h3 class="text-lg font-bold text-gray-800 mb-2">iamboyd.nl</h3>
                                    <p class="text-gray-600">De persoonlijke site van Boyd</p>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="max-w-5xl mx-auto mt-24 pb-24">
                <div class="text-center">
                    <h2 class="text-2xl font-bold text-center mb-8 text-white">Contact</h2>
                    <a href="mailto:info@tnp.pm" class="text-white hover:underline">info@tdn.pm</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HomepageHeader from '@/layout/HomepageHeader'

export default {
  components: { HomepageHeader },
}
</script>
