<template>
  <div class="pt-16 pb-12 flex flex-col">
      <div class="py-16">
        <div class="text-center">
          <p class="text-sm font-semibold text-white uppercase tracking-wide">404 error</p>
          <h1 class="mt-2 text-4xl font-extrabold text-gray-100 tracking-tight sm:text-5xl">Page not found.</h1>
          <p class="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
          <div class="mt-6">
            <a href="/" class="text-base font-medium text-blue-600 hover:text-blue-500">Go back home<span aria-hidden="true"> &rarr;</span></a>
          </div>
        </div>
      </div>
  </div>
</template>

